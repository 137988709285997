import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Customers from "./pages/Customers/Customers";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import "./modalStyle.css";
const Layout = () => {
  return (
    <div>
      <Header />
      <HeaderBottom />
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
         <Route path="/customers" element={<Customers />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/offer" element={<Offer />}></Route>
        <Route path="/product/:_id" element={<ProductDetails />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/paymentgateway" element={<Payment />}></Route>
      </Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
    </Route>,
  ),
);

function App() {
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    // Esta función se ejecuta al montar el componente
    setShowDialog(true);
  }, []);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
    },
  };
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />

      <Modal
        isOpen={showDialog}
        onRequestClose={() => setShowDialog(false)}
        contentLabel="Mensaje de Bienvenida"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          ...customStyles,
        }}
      >
        <h1 className="text-4xl font-semibold text-red-700 text-center mx-auto">Notification</h1>
          <p className="text-base font-normal text-primeColor max-w-[600px] mr-4">
            RUSSIAN HUISHENG INSULATION TECHNOLOGY CO., LTD NOTIFY ALL CUSTOMERS AND PARTNERS: DUE TO THE
            INTERNATIONAL WAR CONFLICT (SPECIAL RUSSIAN OPERATION IN UKRAINE), ALL OUR SHIPMENTS ARE HAVING DELAYS,
            BECAUSE RUSSIAN PORTS ARE SATURATED WITH CARGOES OF MILITARY MATERIALS WHICH ARE PRIORITIZED FOR
            NATIONAL SECURITY. IN ADDITION, TO SAFEGUARD THE INTEGRITY OF THE SHIPMENT, IT IS NECESSARY TO
            ESTABLISH NEW TRANSPORTATION ROUTES. ALL OUR SHIPPINGS TO OTHER CONTINENTS SUCH AS ASIA, OCEANIA AND
            AMERICA WILL HAVE A DELAY OF APPROXIMATELY 90 TO 120 DAYS, WE VERY REGRET THE INCONVENIENCE.
          </p>
        <button style={customStyles.closeButton} onClick={() => setShowDialog(false)}>
          Close
        </button>
      </Modal>
    </div>
  );
}

export default App;
