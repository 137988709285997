import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import {
  customer1,
  customer2,
  customer3,
  customer4,
  customer5,
  customer6,
  customer7,
  customer8,
  customer9,
  customer10,
  customer11,
  customer12,
  customer13,
  customer14,
} from "../../assets/images/index";
const Customers = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Honoral Customers" prevLocation={prevLocation} />
      <div className="pb-10">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">

    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer1} alt="Imagen 1" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer2} alt="Imagen 2" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer3} alt="Imagen 3" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer4} alt="Imagen 4" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer5} alt="Imagen 5" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer6} alt="Imagen 6" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer7} alt="Imagen 7" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer8} alt="Imagen 8" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer9} alt="Imagen 9" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer10} alt="Imagen 10" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer11} alt="Imagen 11" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer12} alt="Imagen 12" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer13} alt="Imagen 13" class="w-full h-40 object-contain rounded-md"></img>
    </div>
    <div class="bg-white p-4 rounded-md shadow-md">
      <img src={customer14} alt="Imagen 14" class="w-full h-40 object-contain rounded-md"></img>
    </div>
  </div>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Customers;
