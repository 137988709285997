import React from "react";
import { Link } from "react-router-dom";
import {
  corrugated_board,
  diamond_dotted_paper,
  cylinders_insulation_technology_weidmann_electrical,
} from "../../../assets/images/index";
import Image from "../../designLayouts/Image";

const Sale = () => {
  return ( 
    <div className="py-6 flex flex-col md:flex-row items-center justify-between gap-4 lg:gap-10">
      <div className="w-full md:w-2/3 lg:w-1/2 h-full">
        <Link to="/shop">
          <Image className="h-full w-full object-cover" imgSrc={corrugated_board} />
        </Link>
      </div>
      <div className="w-full md:w-2/3 lg:w-1/2 h-auto flex flex-col gap-4 lg:gap-10">
        <div className="h-1/2 w-full">
          <Link to="/shop">
            <Image className="h-full w-full object-cover" imgSrc={diamond_dotted_paper} />
          </Link>
        </div>
        <div className="h-1/2 w-full">
          <Link to="/shop">
            <Image
              className="h-full w-full object-cover"
              imgSrc={cylinders_insulation_technology_weidmann_electrical}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sale;
