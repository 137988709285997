import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="About" prevLocation={prevLocation} />
      <div className="pb-10">
        <h1 className="max-w-[100%] text-base text-lightText mb-2 text-justify">
          <span className="text-primeColor font-semibold text-lg">Russian Huisheng Insulation Technology Co., Ltd.</span>{" "}
          is a wholly-owned subsidiary established in Moscow by Huisheng Group Co., Ltd. It is one of the key high-tech enterprises by RUSSIA - CHINESSE COOPERATION PROGRAM. The main products are transformer boards and insulation components. Among them, "ultra high voltage insulation products" became the National Torch Program of the Ministry of Science and Technology in 2011. The project won the second prize in Shandong's Outstanding Achievements in Technological Innovation. 32 patents were obtained, including 2 invention patents and 30 utility model patents. 
        </h1>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
