import {
  clack_bands,
  collar_with_pacers,
  Shield_Ring_Static_Ring,
  laminated_boards,
  corrugated_board,
  diamond_dotted_paper,
  cylinders_insulation_technology_weidmann_electrical,
  clamping_ring,
  crepe_paper_tube,
  Crepe_papers,
  laminated_end_ring,
  Rounded_End_Ring,
  spacers,
  stepped_spacers,
  strips,
  wedge_spacers,
} from "../assets/images/index";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "/contact",
  },
  {
    _id: 1005,
    title: "Honoral Customers",
    link: "/customers",
  },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img: clack_bands,
    productName: "Clack bands",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Clack band is used in oil duct of coil",
  },
  {
    _id: "202",
    img: collar_with_pacers,
    productName: "Collar with spacers",
    price: "180.00",
    color: "Gray",
    badge: true,
    des: "The collar with spacer is a part of major insulation between windings and yokes, used between outside of end insulation and yokes to support end coils, to balance and distribute axial pressure of windings and ensure the strips without axial pressure when compressing windings.",
  },
  {
    _id: "203",
    img: Shield_Ring_Static_Ring,
    productName: "Shield rings",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Used for evenly distributing the electric field in the winding end to avoid  the breakdown  at  the maximum  surge voltage. During the  operation  of  the transformer, it also bears the short-circuit force. The framework of shield rings are made by the same method as end rings, and bound by aluminum foils, crepe papers and so on.",
  },
  {
    _id: "204",
    img: laminated_boards,
    productName: "Laminated boards",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Laminated  board  is  produced  by  laminating  and  pressing  of transformer boards together with adhesive with the advantages of high density, better mechanical and electrical strength and low compressibility, they are suitable for insulation parts in transformers with high mechanical and electrical performance. The maximum dimension we can produce is 3200*3200*180mm(Th).",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: 1001,
    img: clack_bands,
    productName: "Clack bands",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Clack band is used in oil duct of coil",
  },
  {
    _id: 1002,
    img: collar_with_pacers,
    productName: "Collar with spacers",
    price: "180.00",
    color: "Gray",
    badge: true,
    des: "The collar with spacer is a part of major insulation between windings and yokes, used between outside of end insulation and yokes to support end coils, to balance and distribute axial pressure of windings and ensure the strips without axial pressure when compressing windings.",
  },
  {
    _id: 1003,
    img: Shield_Ring_Static_Ring,
    productName: "Shield rings",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Used for evenly distributing the electric field in the winding end to avoid  the breakdown  at  the maximum  surge voltage. During the  operation  of  the transformer, it also bears the short-circuit force. The framework of shield rings are made by the same method as end rings, and bound by aluminum foils, crepe papers and so on.",
  },
  {
    _id: 1004,
    img: laminated_boards,
    productName: "Laminated boards",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Laminated  board  is  produced  by  laminating  and  pressing  of transformer boards together with adhesive with the advantages of high density, better mechanical and electrical strength and low compressibility, they are suitable for insulation parts in transformers with high mechanical and electrical performance. The maximum dimension we can produce is 3200*3200*180mm(Th).",
  },
  {
    _id: 1005,
    img: corrugated_board,
    productName: "Corrugated boards",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Corrugated boards is a kind of oil gap insulation for small-sized transformer, which is simple assembly and improved the distribution of solid insulation material  in  oil  duct,  comparing  uniform  allocation  of strips  in radial direction. The distribution of solid insulation material is very uniform in the oil duct of corrugated board, without vertical plane to the electric field, the oil duct strength is improved by 15% and kept reliability.",
  },
  {
    _id: 1006,
    img: diamond_dotted_paper,
    productName: "Diamond dotted paper (DDP)",
    price: "180.00",
    color: "Gray",
    badge: false,
    des: "DDP is a kind of insulation material which is painted by specially modified epoxy resin on the insulating paper. The  product is  widely  used  for  layer  insulation and  turn-insulation of  oil  immersed transformers. Its coating layer begins to melt and has an adhering function at a certain temperature when the coils are drying, and the coating layer begins to solidify to make winding coils adhere together into one unit with the increased temperature. The bond strength of epoxy resin could avoid changing coil position when electric power shorts out, so  that  they  can  make  sure  insulation  material  with  good  electric  and  strength performances. Due  to  the  resin  of  DDP distributed in  diamond shape, it  assures oil immersed and gas in insulation material can be removed, and avoid electric corona and partial discharge to guarantee the insulation structure safe and reliable.",
  },
  {
    _id: 1007,
    img: cylinders_insulation_technology_weidmann_electrical,
    productName: "Cylinders",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Mainly used for the winding, improving the winding mechanical strength and anti-short-circuit ability.",
  },
  {
    _id: 1008,
    img: clamping_ring,
    productName: "Clamping rings",
    price: "220.00",
    color: "Black",
    badge: false,
    des: "Used to clamp the whole winding and bear the short circuit force for improving overall mechanical strengt which influences the winding anti-short circuit ability directly.",
  },
  {
    _id: 1009,
    img: crepe_paper_tube,
    productName: "Crepe paper tubes",
    price: "44.00",
    color: "Black",
    badge: true,
    des: "Made of crepe papers through special process, mainly used as insulating package materials of leads inside oil-immersed transformers. Storage Stored in a dry and clean room, without being pressed, and kept away from fire, heat or direct sunlight.",
  },
  {
    _id: 1010,
    img: Crepe_papers,
    productName: "Crepe papers",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "Made of insulating paper which is produced by 100% sulphate coniferous wood pulp after processing by the special  wrinkling process. With good oil-soluble performance it  is widely used in oil-immersed transformers, instrument transformers, power reactors etc. For the package of the insulation of the lead parts to improve the electrical and mechanical properties of the insulation structure",
  },
  {
    _id: 1011,
    img: laminated_end_ring,
    productName: "Laminated end rings",
    price: "80.00",
    color: "Mixed",
    badge: true,
    des: "Used to clamp or support the coil, also as the framework of shield rings.",
  },
  {
    _id: 1012,
    img: Rounded_End_Ring,
    productName: "Laminated end rings",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Used to clamp or support the coil, also as the framework of shield rings.",
  },
  {
    _id: 1013,
    img: spacers,
    productName: "Spacers",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "In the coil winding, forming to the section axial cooling channels and section insulation. The winding wire turns are pressed tightly by the axial compression force spacers pass, therefore, the spacer should have certain compressive strength, the less  compressibility the better, but it should be elastic. Spacers are milled by the imported machines, and can be manufactured according to drawings",
  },

  {
    _id: 1014,
    img: stepped_spacers,
    productName: "Stepped spacers",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "In the coil winding, forming to the section axial cooling channels and section insulation. The winding wire turns are pressed tightly by the axial compression force spacers pass, therefore, the spacer should have certain compressive strength, the less  compressibility the better, but it should be elastic. Spacers are milled by the imported machines, and can be manufactured according to drawings",
  },
  {
    _id: 1015,
    img: strips,
    productName: "Strips",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Used as the winding internal support and to form the internal axial cooling channels in the coil winding. There are mainly rectangular strips, T-strips, trapezoidal strips and special-shaped strips according to the section shape. Our company strips have the following characteristics: high mechanical strength, electrical strength, and precise product size.",
  },
  {
    _id: 1016,
    img: wedge_spacers,
    productName: "Wedge Spacers",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "Usually used in the coil transition or incomplete turns position, the transposition of the spiral coil etc. to adjust the height of axial wire turns. Leveling up the transition position, preventing  the  scissors  mouth,  letting  the  axial  pressure  evenly distribute on spacers, avoiding overloading spacers.",
  },
];
// =================== PaginationItems End here =================
