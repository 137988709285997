import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  clack_bands,
  collar_with_pacers,
  Shield_Ring_Static_Ring,
  laminated_boards,
} from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const NewArrivals = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16">
      <Heading heading="New Arrivals" />
      <Slider {...settings}>
        <div className="px-2">
          <Product
            _id="100001"
            img={clack_bands}
            productName="Clack bands"
            price="44.00"
            color="Black"
            badge={true}
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100002"
            img={collar_with_pacers} 
            productName="Collar with spacers"
            price="250.00"
            color="Black"
            badge={true}
            des="The collar with spacer is a part of major insulation between windings and yokes, used between outside of end insulation and yokes to support end coils, to balance and distribute axial pressure of windings and ensure the strips without axial pressure when compressing windings."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100003"
            img={Shield_Ring_Static_Ring}
            productName="Shield rings"
            price="80.00"
            color="Mixed"
            badge={true}
            des="Used for evenly distributing the electric field in the winding end to avoid  the breakdown  at  the maximum  surge voltage. During the  operation  of  the transformer, it also bears the short-circuit force. The framework of shield rings are made by the same method as end rings, and bound by aluminum foils, crepe papers and so on."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100004"
            img={laminated_boards}
            productName="Laminated boards"
            price="60.00"
            color="Mixed"
            badge={true}
            des="Laminated  board  is  produced  by  laminating  and  pressing  of transformer boards together with adhesive with the advantages of high density, better mechanical and electrical strength and low compressibility, they are suitable for insulation parts in transformers with high mechanical and electrical performance. The maximum dimension we can produce is 3200*3200*180mm(Th)."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100005"
            img={collar_with_pacers}
            productName="Collar with spacers"
            price="60.00"
            color="Mixed"
            badge={true}
            des="The collar with spacer is a part of major insulation between windings and yokes, used between outside of end insulation and yokes to support end coils, to balance and distribute axial pressure of windings and ensure the strips without axial pressure when compressing windings."
          />
        </div>
      </Slider>
    </div>
  );
};

export default NewArrivals;
