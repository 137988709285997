import React from "react";
import { Link } from "react-router-dom";
import { productOfTheYear } from "../../../assets/images";
import ShopNow from "../../designLayouts/buttons/ShopNow";
import Image from "../../designLayouts/Image";

const YearProduct = () => {
  return (

      <div className="w-full h-80 mb-0 bg-[#f3f3f3] md:bg-transparent relative font-titleFont">
        <div className="w-full md:w-2/3 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-56 flex flex-col items-start gap-2 text-justify justify-center">
          <h1 className="text-4xl font-semibold text-red-700 text-center mx-auto">Notification</h1>
          <p className="text-base font-normal text-primeColor max-w-[600px] mr-4">
            RUSSIAN HUISHENG INSULATION TECHNOLOGY CO., LTD NOTIFY ALL CUSTOMERS AND PARTNERS: DUE TO THE
            INTERNATIONAL WAR CONFLICT (SPECIAL RUSSIAN OPERATION IN UKRAINE), ALL OUR SHIPMENTS ARE HAVING DELAYS,
            BECAUSE RUSSIAN PORTS ARE SATURATED WITH CARGOES OF MILITARY MATERIALS WHICH ARE PRIORITIZED FOR
            NATIONAL SECURITY. IN ADDITION, TO SAFEGUARD THE INTEGRITY OF THE SHIPMENT, IT IS NECESSARY TO
            ESTABLISH NEW TRANSPORTATION ROUTES. ALL OUR SHIPPINGS TO OTHER CONTINENTS SUCH AS ASIA, OCEANIA AND
            AMERICA WILL HAVE A DELAY OF APPROXIMATELY 90 TO 120 DAYS, WE VERY REGRET THE INCONVENIENCE.
          </p>
        </div>
      </div>

  );
};

export default YearProduct;
