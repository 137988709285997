import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaYoutube, FaLinkedin, FaGithub } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { paymentCard } from "../../../assets/images";
import Image from "../../designLayouts/Image";

const Footer = () => {
  const [emailInfo, setEmailInfo] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const emailValidation = () => {
    return String(emailInfo)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSubscription = () => {
    if (emailInfo === "") {
      setErrMsg("Please provide an Email !");
    } else if (!emailValidation(emailInfo)) {
      setErrMsg("Please give a valid Email!");
    } else {
      setSubscription(true);
      setErrMsg("");
      setEmailInfo("");
    }
  };
  return (
    <div className="w-full bg-[#F5F5F3] py-5 ">
      <div className="max-w-container  mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-1 px-4 gap-6">
        <div className="col-span-1">
          <FooterListTitle title=" More about Russian Huisheng Insulation Technology Co., Ltd. Shop" />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[100%] text-justify">
            The main products are transformer boards and insulation components. Among them, "ultra high voltage insulation products" became the National Torch Program of the Ministry of Science and Technology in 2011. The project won the second prize in Shandong's Outstanding Achievements in Technological Innovation. 32 patents were obtained, including 2 invention patents and 30 utility model patents.<br></br>
            Transformer boards and insulation components are insulating materials with high technology content. High quality raw materials, advanced technology, modern production lines, perfect quality control system and high level staff guarantee the high quality of the insulation products.
            </p>
            {/* <ul className="flex items-center gap-2">
              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaYoutube />
                </li>
              </a>
              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaGithub />
                </li>
              </a>
              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaLinkedin />
                </li>
              </a>
            </ul> */}
          </div>
        </div>
        
          
        
      </div>
    </div>
  );
};

export default Footer;
